export default defineNuxtRouteMiddleware(async (_to, _from) => {
  try {
    // Disable this legacy middleware for now
    return null

    const onboardingCompleted = await $fetch('/api/v2/onboarding/check-completed', {
      method: 'GET',
      headers: useRequestHeaders(['cookie']),
    })

    useLogger().debug('onboarding-check middleware - onboardingCompleted', onboardingCompleted)

    const alreadyRedirected = await useState('alreadyRedirected', () => false)

    if (onboardingCompleted && !alreadyRedirected.value) {
      // If onboarding is completed, redirect to home page
      useLogger().debug(`Onboarding completed, redirecting to ${useRuntimeConfig().public.auth.defaultUserHome}`)
      alreadyRedirected.value = true
      return navigateTo(useRuntimeConfig().public.auth.defaultUserHome)
    }
    else {
      if (_to.path !== '/onboarding') {
      // If onboarding is not completed, redirect to onboarding page
        useLogger().debug('Onboarding not completed, redirecting to /onboarding')
        return navigateTo('/onboarding')
      }
    }
  }
  catch (error) {
    console.error('Auth error: ', error)
  }
  // try {

  // }
  // catch (error) {
  //   console.error('Error checking onboarding status:', error)
  //   // In case of error, redirect to onboarding as a safety measure
  //   return navigateTo('/onboarding')
  // }
})
